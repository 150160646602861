.main {
  // margin: 3.571rem 0 0;
  margin: calc(3.571rem + 2.142rem) 0 0;
  // margin: 5.713rem 0 0;
  position: relative;

  @media (max-width: 767px) {
    margin: calc(3.571rem + 1.714rem) 0 0;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 6.286rem;
      top: 13.071rem;
      background: linear-gradient(180deg, #d1487d 0%, #f476a7 100%);
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media (max-width: 767px) {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      width: 100%;
      height: 13.071rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }
  }

  &__img {
    position: absolute;
    bottom: -11rem;
    left: -2.857rem;
    width: 42.143rem;

    @media (max-width: 767px) {
      width: 14.286rem;
      bottom: auto;
      left: 2.429rem;
      top: 8.5rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &Body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 43.571rem;
    padding: 0 0 4rem;

    @media (max-width: 767px) {
      height: auto;
      margin: 6.5rem 0 0;
      padding: 0;
      align-items: center;
    }

    &__logo {
      height: 6.5rem;

      @media (max-width: 767px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__title {
      margin: 2.286rem 0 0;

      @media (max-width: 767px) {
        margin: 0;
      }

      span {
        display: block;

        color: #fff;
        text-align: center;
        font-family: CoFo Sans;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        line-height: 1.05;

        &:nth-child(1) {
          font-size: 3.714rem;

          @media (max-width: 767px) {
            font-size: 2.357rem;
          }
        }
        &:nth-child(2) {
          font-size: 3.071rem;

          @media (max-width: 767px) {
            font-size: 1.929rem;
          }
        }
        &:nth-child(3) {
          margin: 0.2rem 0 0;
          font-size: 2.357rem;

          @media (max-width: 767px) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
