.select {
  position: relative;
  z-index: 1;
  border-radius: 0.571rem;
  background: #fff;

  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 3.429rem;
    color: #11335f;
    font-family: 'CoFo Sans';
    font-size: 1.286rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    padding: 0 1.143rem;

    @media (max-width: 767px) {
      height: 2.857rem;
      font-size: 1.143rem;
      padding: 0 0.857rem;
    }

    svg {
      position: absolute;
      right: 1.429rem;
      top: 1.25rem;
      width: 0.857rem;
      transition: all 0.3s ease;

      @media (max-width: 767px) {
        top: 1.1rem;
        right: 0.929rem;
        width: 0.714rem;
      }
    }

    &.open {
      svg {
        transform: scale(1, -1);
      }
    }
  }
  &List {
    width: 100%;
    position: absolute;
    top: calc(100% + 0.143rem);
    padding: 0.857rem 1.143rem;
    max-height: 0;
    overflow-y: scroll;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    border-radius: 0.571rem;
    border: 0.071rem solid #fff;
    background: #fff;

    transition: all 0.3s ease;

    @media (max-width: 767px) {
      padding: 0.714rem 0.857rem;
    }

    &::before {
      content: '';
      display: block;
      height: 0.4rem;
      background-color: transparent;
      position: absolute;
      top: -0.4rem;
      left: 0;
      right: 0;
    }

    &.open {
      max-height: 14.286rem;
      opacity: 1;
      pointer-events: unset;
      visibility: visible;
    }

    &__item {
      cursor: pointer;
      color: #11335f;
      font-family: CoFo Sans;
      font-size: 1.286rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      transition: all 0.3s ease;

      @media (max-width: 767px) {
        font-size: 1.143rem;
      }

      &:hover {
        color: #f577a8;
      }

      &:not(:first-child) {
        margin: 0.857rem 0 0;
      }
    }
  }
}
