.header {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 2.142rem;
  right: 0;
  background: #11335f;

  @media (max-width: 767px) {
    position: absolute;
    top: 1.71rem;

    &.open {
      transform: translate(0, 0rem) !important;
      top: 0;

      .headerMenu {
      }
    }
  }

  // &.scroll {
  //   position: fixed;
  //   transform: translate(0, -105%);
  // }

  // &.fixed {
  //   transform: translate(0);
  // }

  &.fixed {
    position: fixed;
    // transform: translate(0, -105%);
    transform: translate(0, -175%);
    transition: transform 0.5s ease;

    &.open {
      transform: translate(0, -2.142rem);

      @media (max-width: 767px) {
        transform: translate(0, -1.71rem);
      }
    }
  }

  &Body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.714rem;

    @media (max-width: 767px) {
      justify-content: unset;
    }

    &__burger {
      position: relative;
      height: 1rem;
      width: 1.714rem;

      &.active {
        span {
          background-color: #fff;

          &:nth-child(1) {
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
          }
          &:nth-child(2) {
            transform: translate(-100%, -50%) scale(0);
          }
          &:nth-child(3) {
            bottom: auto;
            top: 50%;
            transform: translate(0, -50%) rotate(-45deg);
          }
        }
      }

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 0.143rem;
        background-color: #f476a7;
        border-radius: 0.714rem;
        transition: all 0.3s ease;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 50%;
          transform: translate(0, -50%);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
    }

    &__logo {
      height: 2.143rem;
      margin: 0 auto;
      padding: 0 1.714rem 0 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &Menu {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      background-color: #11335f;
      flex-direction: column;
      justify-content: flex-start;
      padding: 5.571rem 0 0;

      transform: translate(-100%, 0);
      transition: all 0.4s ease;

      &.active {
        transform: translate(0);
      }
    }

    &__item {
      cursor: pointer;
      color: #fff;
      font-family: CoFo Sans;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;

      transition: all 0.3s ease;

      &:hover {
        color: #f476a7;
      }

      &.active {
        color: #f476a7;
      }

      @media (max-width: 767px) {
        font-size: 1.143rem;
      }

      &:not(:first-child) {
        margin: 0 0 0 3.429rem;

        @media (max-width: 767px) {
          margin: 1.714rem 0 0;
        }
      }
    }
  }
}

// @keyframes headerOpen {
//   0% {
//     transform: translate(0, -105%);
//   }
//   100% {
//     transform: translate(0);
//   }
// }
