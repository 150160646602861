.how {
  padding: 6.571rem 0 0;
  background-image: url(./assets/clouds.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 -4rem;

  @media (max-width: 767px) {
    scroll-margin-block-start: 16rem;
    padding: 0;
    margin: 3.286rem 0 0;
    background: none;
  }

  &__title {
    color: #11335f;
    text-align: center;
    font-family: CoFo Sans;
    font-size: 2.714rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 1.714rem;
    }
  }
  &__subtitle {
    margin: 0.429rem 0 0;
    color: #11335f;
    text-align: center;
    font-family: CoFo Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 767px) {
      margin: 0;
      font-size: 1.143rem;
    }
  }

  &Body {
    display: flex;
    justify-content: space-between;
    margin: 2.143rem 0 0;

    @media (max-width: 767px) {
      margin: 1.429rem 0 0;
      flex-wrap: wrap;
    }
  }

  &Step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 21.5rem;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      width: 100% !important;
      align-items: flex-start;
      padding: 0 0 0 1.857rem;

      &:not(:first-child) {
        margin: 3.571rem 0 0 !important;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 7rem;
      left: 0;
      background-image: url(./assets/arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 3.143rem;
      height: 2.143rem;

      @media (max-width: 767px) {
        display: none !important;
      }
    }

    &:nth-child(1) {
      &::before {
        display: none;
      }

      .howStep__img {
        width: 16.429rem;
        margin: 0 2rem 0 0;

        @media (max-width: 767px) {
          width: 11.786rem;
        }
      }
    }
    &:nth-child(2) {
      width: 26rem;
      margin: 0 0 0 2rem;

      &::before {
        left: -6rem;
      }

      .howStep__img {
        width: 16.429rem;
        margin: 0 6rem 0 0;

        @media (max-width: 767px) {
          width: 12.143rem;
        }
      }
    }
    &:nth-child(3) {
      align-items: flex-start;

      &::before {
        left: -7rem;
      }

      .howStep__img {
        width: 15.857rem;
        margin: 0 0 0 1rem;

        @media (max-width: 767px) {
          width: 11.429rem;
        }
      }
    }

    &__img {
      height: 16.429rem;

      @media (max-width: 767px) {
        height: auto;
        margin: 0 auto !important;
        display: flex;
        transform: translate(-1rem, 0);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__info {
      display: flex;

      &Num {
        color: #11335f;
        font-family: CoFo Sans;
        font-size: 8.857rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-transform: uppercase;

        @media (max-width: 767px) {
          font-size: 5.143rem;
        }
      }
      &Text {
        padding: 0.571rem 0 0;
        margin: 0 0 0 0.857rem;

        @media (max-width: 767px) {
          margin: 0 0 0 1rem;
          padding: 0.286rem 0 0;
        }
      }
      &Title {
        color: #fff;
        font-family: CoFo Sans;
        font-size: 1.929rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        text-transform: uppercase;

        @media (max-width: 767px) {
          font-size: 1.143rem;
        }
      }
      &Descr {
        color: #11335f;
        font-family: CoFo Sans;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        // text-transform: lowercase;

        @media (max-width: 767px) {
          font-size: 1.143rem;
        }
      }
    }
  }
}
