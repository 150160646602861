* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

/* Fonts */
@font-face {
  font-family: 'CoFo Sans';
  font-display: swap;
  src: url('./assets/fonts/CoFoSans-Medium.woff2') format('woff2'),
    url('./assets/fonts/CoFoSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'CoFo Sans';
  font-display: swap;
  src: url('./assets/fonts/CoFoSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/CoFoSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'CoFo Sans';
  font-display: swap;
  src: url('./assets/fonts/CoFoSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/CoFoSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

::selection {
}

html {
  font-size: 0.933vw;

  @media (max-width: 767px) {
    font-size: 4.376vw;
  }
}

body {
  font-family: 'CoFo Sans';
  font-weight: 500;
  background: #f476a7;

  width: 100%;
  overflow-y: scroll;

  &.lock {
    overflow: hidden;
  }
}

#root {
  height: 100%;
}

.container {
  width: 83.429rem;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;

  overflow: hidden;
  flex: 1 1 auto;
}

.top-info {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 2.142rem;

  color: #11335f;
  text-align: center;
  font-family: CoFo Sans;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;

  @media (max-width: 767px) {
    height: 1.714rem;
    font-size: 0.785rem;
    letter-spacing: -0.0117rem;
  }

  a {
    color: #11335f;
    text-decoration-line: underline;
    margin: 0 0 0 0.3rem;

    @media (max-width: 767px) {
      margin: 0 0 0 0.15rem;
    }
  }

  &_mobile {
    display: none;
  }

  @media (max-width: 767px) {
    font-size: 0;

    .top-info_mobile {
      display: flex;
      font-size: 0.785rem;
    }
  }
}
