.trophy {
  margin: 5.429rem 0 0;
  scroll-margin-top: 5rem;
  // background-image: url(./assets/bg.jpg);
  // background-repeat: no-repeat;
  // background-size: 100%;
  // background-position: 0 -4rem;

  @media (max-width: 767px) {
    scroll-margin-block-start: 6rem;
    position: relative;
    margin: 5rem 0 0;
    padding: 0 0 11.714rem;

    background-image: url(./assets/bg-mob.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left 0 bottom -7rem;

    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-image: url(./assets/clouds-mob.jpg);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: left 0 top 3rem;
    }
  }

  &__title {
    color: #11335f;
    text-align: center;
    font-family: CoFo Sans;
    font-size: 2.714rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 1.714rem;
    }
  }

  &Body {
    margin: 3rem 0 0;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      margin: 1.571rem 0 0;
      flex-wrap: wrap;
    }

    &__item {
      flex: 1 1 auto;

      @media (max-width: 767px) {
        width: 100%;

        &:not(:first-child) {
          margin: 1.857rem 0 0;
        }
      }

      &Icon {
        height: 13.071rem;

        @media (max-width: 767px) {
          height: 10.571rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &Title {
        color: #11335f;
        text-align: center;
        font-family: CoFo Sans;
        font-size: 1.571rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        @media (max-width: 767px) {
          font-size: 1.143rem;
        }
      }
    }
  }
}
