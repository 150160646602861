.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 9;
  left: 50%;
  bottom: 2rem;
  transform: translate(-50%, 0);

  width: 80.714rem;
  border-radius: 0.571rem;
  background: #fff;
  padding: 2.143rem 2rem 1.429rem;

  @media (max-width: 767px) {
    width: calc(100% - 1.714rem);
    bottom: 1rem;
    border-radius: 0.429rem;
    padding: 1.071rem;
  }

  &__text {
    color: #11335f;
    text-align: center;
    font-family: CoFo Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    @media (max-width: 767px) {
      font-size: 0.857rem;
    }
  }
  &Btns {
    display: flex;
    justify-content: center;
    margin: 1.429rem 0 0;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      margin: 1.786rem 0 0;
    }

    &__item {
      width: 10.714rem;
      height: 2.143rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.571rem;
      border: 0.071rem solid #11335f;
      background: #11335f;

      color: #fff;
      text-align: center;
      font-family: CoFo Sans;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;

      @media (max-width: 767px) {
        width: 12rem;
      }

      &:not(:first-child) {
        margin: 0 0 0 1.071rem;

        @media (max-width: 767px) {
          margin: 1.071rem 0 0;
        }
      }

      &.close {
        background-color: #fff;
        color: #11335f;
      }
    }
  }
  &__privacy {
    position: relative;
    margin: 1.429rem 0 0;
    align-self: center;

    color: #11335f;
    text-align: center;
    font-family: CoFo Sans;
    font-size: 0.929rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 767px) {
      margin: 1.571rem 0 0;
      font-size: 0.786rem;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #11335f;
      position: absolute;
      bottom: 0;
    }
  }
}
